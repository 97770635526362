import React from 'react'
import HubspotForm from 'react-hubspot-form'
import Layout from '../../../components/layout'

import {
	Container,
	Section,
} from '../../../components/layouts'

import {
	Subtitle,
	Paragraph,
	BlogAuthor,
	Link,
	Footer
} from '../../../components/ui'

import powerPageHeading from '../../../assets/images/powerpage_heading.png'

import theme from '../../../theme/theme'

const {
	intro,
} = theme.sections

class IndexPage extends React.Component {
	constructor (props) {
		super(props)
	}

	componentDidMount() {
		window.scrollTo(0, -1500)
	}

	render () {
		return (
			<Layout>
				<Section id="intro" bg={intro.bg} paddingTop={'100px'}>
					<Container  >
						<img alt="Turning Millennials into Donors" src={powerPageHeading} width="100%"/>

						<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }}>Actionable tips and methods for nonprofits looking to reach and engage millennials as active donors </Subtitle><br/>

						<Paragraph>
          Millennials--the generation rapidly changing the way we eat, work, exercise, shop, and now, the way we give. Characterized as the generation born between 1980 and 2000, millennials today range from the ages 18 to 38. With millennials now making up the largest portion of the population, it is imperative that nonprofits begin engaging and securing them as donors. But despite making up more than a quarter of the population, millennials account for only 11% of charitable giving.
							<br/><br/>This may come as a surprise, but millennials have really good intentions. Often referred to as the purpose-driven generation, millennials care about doing social good and yearn to make an impact on the world around them. But there’s a problem--according Blackbaud’s Next Generation of American Giving study, only 25% of millennials believe that monetary giving is the best way to make a difference with charitable organizations.
							<br/><br/>So how can you, as a nonprofit, turn well-meaning, “I’m-going-to-save-the-world” millennials into tangible donors? And even better--how can you turn them into recurring donors?
          In this guide, I cover seven approaches that will help your nonprofit both involve and capture the millennial generation.

							<ul>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter1/'}>Chapter 1: 3 ways to get Millennials directly engaged with your organization</Link></li>
								<li>Chapter 2: Generate Social Currency (aka Millennial Candy)</li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter3/'}>Chapter 3: 4 Methods of Transparency that Millennials Love</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter4/'}>Chapter 4: How To Make Your Social Media Attractive and Effective</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter5/'}>Chapter 5: Turn the Tables By Getting Millennials To Fundraise For You</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter6/'}>Chapter 6: How to get Millennials to Pull the Trigger and Donate (in 2 minutes)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter7/'}>Chapter 7: Turning One-Time Donors into Lifetime Donors</Link></li>
							</ul>

							<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }} id="chapter2">Chapter 2: Generate Social Currency (aka Millennial Candy) </Subtitle>
                Despite stereotypes of being entitled and self-seeking, millennials are actually also very generous and genuinely want to make an impact on the world around them. Not only that, but they love to tell everyone about it. 50% of millennials share about the causes they support on social media.
							<br/><br/>Introducing a little thing I like to call social currency: the stuff that people share to make others like them. What’s not likeable about a person who supports an awesome cause? (Potentially a lot, but that’s beside the point). As a nonprofit, you have the capability to be a treasure chest of social currency for your millennial donors.
							<br/><br/>There are 3 simple but <strong>EFFECTIVE</strong> methods for generating millennial social currency:
							<ul>
								<li><strong>Create a social media campaign.</strong> <Link style={{ display: 'inline' }} href={'https://enditmovement.com/'}>The End It Movement,</Link> a coalition of 16 nonprofits fighting human trafficking and slavery, is killing the social media campaign game. On February 22 of every year, millions of people draw a red x on their hand and post it to their social media to display their commitment to the cause. The red x has been shared over 500 million times. Granted, The End It Movement is composed of 16 major nonprofits--but don’t underestimate the power of discovering creative ways to encourage millennials to support and spread awareness about your cause.</li>
								<li><strong>Give away “swag” </strong>By “swag”, I mean the cool products and merchandise that include your brand. Use gifts as incentives for donations. “Donate $50 and we’ll send you a free hat, t-shirt, etc.” Not only are Millennials incentivized to donate, but branded “swag” serves as free brand recognition. It’s wearable social currency, essentially.</li>
								<li><strong>Publish stories and make them sharable </strong>As a nonprofit, your work is making an impact somewhere to someone. Tell those stories. Write and publish them on your website. And here’s the key: include links for people to share them on their social media. Check out Hubspot’s <Link style={{ display: 'inline' }} href="https://blog.hubspot.com/blog/tabid/6307/bid/29544/the-ultimate-cheat-sheet-for-creating-social-media-buttons.aspx">guide for creating shareable social media buttons.</Link></li>
							</ul>

							<hr style={{ margin: '1em 0' }}/>

							<Subtitle fontSize={'30px'}>
							Looking for the full e-book?
							</Subtitle>
							<Paragraph
								fontSize={'21px'}
								marginRight={"2em"}
								fontWeight={'200'}>Fill out this form to receive our free guide <span style={{ fontStyle: 'italic' }}>Millennials To Donors</span></Paragraph>
							<HubspotForm
								id="reactHubspotForm"
								portalId="4829666"
								formId="1c650ab1-5fd3-4250-88c3-269180d71e88"
								loading={<div>{'Loading...'}</div>}
							/>

							<hr style={{ margin: '1em 0' }}/>
							<br/>
							<br/>
              Now I’ll turn it over to you. What do you think of this guide? Is there anything I missed or you want to see covered? Please send me your thoughts at <Link style={{ display: 'inline' }} href="mailto:laura@flourishchange.com">laura@flourishchange.com</Link>.

						</Paragraph>
						<BlogAuthor author="laura"/>
					</Container>

				</Section>
				</Layout>
		)
	}
}

export default IndexPage
